import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

import { withArticle } from "./withArticle"
import { Sections } from "../Sections/Sections"
import PageTitle from "../PageTitle/PageTitle"
import ArticleFooter from "./ArticleFooter"

const Date = tw.p`
  uppercase py-3-2 opacity-70
`

export const Article = withArticle(({ title, paths, date, content, next, previous, shareImage, summary }) => {
  return (
    <>
      <PageTitle title={title} background={`lightest`} paths={paths}>
        {date && <Date>{date}</Date>}
      </PageTitle>
      <Sections page={content} />
      <ArticleFooter
        previous={previous}
        next={next}
        shareImage={{
          src: shareImage?.asset?.url,
          description: summary,
        }}
      />
    </>
  )
})

Article.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
    previous: PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
    next: PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.string,
    }),
    shareImage: PropTypes.shape({
      src: PropTypes.string,
      description: PropTypes.string,
    }),
  }),
}

export default Article
