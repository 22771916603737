import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import Share from "../Share/Share"
import { BackgroundContainer, StyledContainer } from "../Styled/Container"
import { isBrowser } from "../../utils/domUtils"
import { useApp } from "../../hooks/useApp"

const Container = tw.div`pt-3-2 md:pt-4-8 pb-4 md:pb-7-2 flex flex-row justify-between border-t border-beige`

const Navigation = styled.div`
  ${tw`flex flex-col text-16 leading-1.5 w-1/2 md:w-full`}
  ${({ direction }) => {
    if (direction === `prev`) return tw`items-start text-left pr-2-4`
    if (direction === `next`) return tw`items-end text-right pl-2-4`
  }}
`

const StyledLink = styled(Link)`
  ${tw`underline`}
`

const Desktop = tw.div`
  hidden md:block
`

const Mobile = tw.div`
  flex md:hidden justify-center pb-2-4
`

const ArticleFooter = ({ previous, next, shareImage }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  return (
    <BackgroundContainer background={`transparent`}>
      <Mobile>{isBrowser && <Share link={window.location.href} image={shareImage} />}</Mobile>
      <StyledContainer>
        <Container>
          <Navigation direction={`prev`}>
            {previous && (
              <>
                <span>Previous article</span>
                <StyledLink to={`${routes[`ARTICLES`]}/${previous?.slug?.current}`}>{previous?.title}</StyledLink>
              </>
            )}
          </Navigation>
          <Desktop>{isBrowser && <Share link={window.location.href} image={shareImage} />}</Desktop>
          <Navigation direction={`next`}>
            {next && (
              <>
                <span>Next article</span>
                <StyledLink to={`${routes[`ARTICLES`]}/${next?.slug?.current}`}>{next?.title}</StyledLink>
              </>
            )}
          </Navigation>
        </Container>
      </StyledContainer>
    </BackgroundContainer>
  )
}

ArticleFooter.propTypes = {
  previous: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  }),
  Next: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  }),
  image: PropTypes.shape({
    src: PropTypes.string,
    description: PropTypes.string,
  }),
}

export default ArticleFooter
