import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"

import Icon from "../Icon"
import { socialShare } from "../../utils/socialShareUtils"

const Wrapper = tw.div`
  flex flex-row items-center
`

const Button = tw.button`
  focus:outline-none
`

const Link = tw.a``

const Title = tw.span`mr-0-8`

const Share = ({ link, image, type }) => {
  const subject = type === "wishlist" ? `Check my wishlist on Bared footwear` : `Check this article I found on Bared footwear`
  return (
    <Wrapper>
      <Title>Share</Title>
      <Link href={`mailto:?subject=${subject}&body=${link}`} target={`_blank`} rel={`noopener noreferrer`}>
        <Icon name={`email`} size={`sm`} />
      </Link>
      <Button onClick={() => socialShare(`facebook`, link)}>
        <Icon name={`facebook`} size={`sm`} />
      </Button>
      {image.src && (
        <Button onClick={() => socialShare(`pinterest`, link, image)}>
          <Icon name={`pinterest`} size={`sm`} />
        </Button>
      )}
    </Wrapper>
  )
}

Share.propTypes = {
  link: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    description: PropTypes.string,
  }),
  type: PropTypes.oneOf([`wishlist`, `article`]),
}

Share.defaultProps = {
  type: `article`,
}

export default Share
