import config from "../../config"

export const socialShare = (type, link, image) => {
  switch (type) {
    case "facebook":
      window.open(
        `${config.socialMedia.sharing.facebook}${encodeURIComponent(link)}`,
        "facebook-popup",
        "height=400,width=600",
      )
      break
    case "pinterest":
      window.open(
        `${config.socialMedia.sharing.pinterest}${link}&media=${image.src}&description=${image.description}`,
      )
      break
    default:
      break
  }
}
