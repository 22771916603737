import React from "react"
import { graphql } from "gatsby"

import { Article as Template } from "../components/Article/Article"

export const query = graphql`
  query($id: String!) {
    article: sanityArticle(_id: { eq: $id }) {
      ...GatsbyArticleFragment
    }
    relatedArticles: allSanityArticle {
      edges {
        next {
          slug {
            current
          }
          title
        }
        node {
          slug {
            current
          }
          title
        }
        previous {
          slug {
            current
          }
          title
        }
      }
    }
    template: sanityTemplateArticle {
      additionalShareText
      settingShowSocialShare
    }
  }
`

export default ({ data, ...props }): JSX.Element => <Template {...props} {...data} />
