import React from "react"

import { useApp } from "../../hooks/useApp"

export const withArticle = Component => ({ name = "Article", article, relatedArticles }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { title, categories, content, _createdAt, attributes, slug, image, summary } = article

  const { next, previous } = relatedArticles.edges.find(featArticle => featArticle.node.slug.current === slug.current)

  const date = attributes?.publishedAt ? new Date(attributes?.publishedAt).toLocaleDateString() : new Date(_createdAt).toLocaleDateString()

  const paths =
    categories.length > 0
      ? [
          {
            title: "Home",
            url: "/",
          },
          {
            title: `${categories[0].title}`,
            url: `${routes[`ARTICLES`]}?filter=${categories[0].slug?.current}`,
          },
          {
            title: `${title}`,
          },
        ]
      : [
          {
            title: "Home",
            url: "/",
          },
          {
            title: `${title}`,
          },
        ]

  const page = {
    content: content,
  }

  Component.displayName = name
  return <Component title={title} content={page} date={date} paths={paths} next={next} previous={previous} shareImage={image} summary={summary} />
}
